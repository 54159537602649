import React from 'react'
import styled from 'styled-components'

import {Layout, PageHeader, MetaData} from '../components'
import {Section, BoxedContainer} from '../elements'
import {
  Input,
  Label,
  FieldWrapper,
  Fieldset,
  Select,
  Textarea,
} from '../elements/Form'
import {ButtonPrimary} from '../elements/Buttons'

import Twitter from '../images/inline-svgs/twitter-outline.svg'
import Email from '../images/inline-svgs/email-outline.svg'

const ContactPage = () => (
  <Layout>
    <MetaData
      title="Contact Us | Stylesheets.dev"
      description="Contact us using the form below and we'll be happy to answer your questions."
    />
    <PageHeader
      title="Contact us"
      subtitle="Contact us using the form below and we'll be happy to answer your questions."
    />
    <Section padding="both">
      <BoxedContainer>
        <Wrapper>
          <Form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <Fieldset columns={2}>
              <FieldWrapper>
                <Label htmlFor="contact-name">Name</Label>
                <Input type="text" name="name" id="contact-name" />
              </FieldWrapper>
              <FieldWrapper>
                <Label htmlFor="contact-email">Email</Label>
                <Input type="email" name="email" id="contact-email" required />
              </FieldWrapper>
            </Fieldset>
            <Fieldset>
              <FieldWrapper>
                <Label htmlFor="contact-subject">
                  What do you want to talk about?
                </Label>
                <Select id="contact-subject" name="subject">
                  <option value="none">Select</option>
                  <option value="Theme Support">Theme Support</option>
                  <option value="Consultancy">Custom theme development</option>
                  <option value="Other">Other</option>
                </Select>
              </FieldWrapper>
            </Fieldset>
            <Fieldset>
              <FieldWrapper>
                <Label htmlFor="contact-message">Your message</Label>
                <Textarea id="contact-message" name="message" required />
              </FieldWrapper>
            </Fieldset>
            <ButtonPrimary type="submit">Submit</ButtonPrimary>
          </Form>
          <Aside>
            <Account href="mailto:support@stylesheets.dev" target="_blank">
              <Email />
              support@stylesheets.dev
            </Account>
            <Account
              href="https://twitter.com/messages/compose?recipient_id=1112078736160022528"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter />
              @StylesheetsDev
            </Account>
          </Aside>
        </Wrapper>
      </BoxedContainer>
    </Section>
  </Layout>
)

export default ContactPage

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Form = styled.form`
  padding: 0 0 60px;
  border-bottom: 1px solid #e9e9e9;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding: 0 40px 0 0;
    border-bottom: none;
    border-right: 1px solid #e9e9e9;

    &::after {
      content: 'OR';
      position: absolute;
      right: -10px;
      top: calc(50% - 25px);
      background: white;
      padding: 10px 0;
      font-size: 1.4rem;
      color: ${props => props.theme.color.text.lightest};
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    padding: 25px 60px 25px 0;
  }
`

const Aside = styled.aside`
  padding: 50px 0 0;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding: 0 0 0 40px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    padding: 40px 0 25px 60px;
  }
`

const Account = styled.a`
  color: ${props => props.theme.color.primary.base};
  display: flex;
  align-items: center;
  margin: 0 0 25px;

  svg {
    margin: 0 12px 0 0;
  }

  &:last-child {
    margin: 0;
  }
`
