import styled from 'styled-components'

import Icon from '../../images/icons/chevron-down.svg'

const Select = styled.select`
  height: 52px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url(${Icon});
  background-size: 20px;
  background-position: calc(100% - 15px) 53%;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 1.6rem;
  padding: 0 15px;
  border: 1px solid ${props => props.theme.color.grey.darker};
  border-radius: 6px;
  color: ${props => props.theme.color.text};
  outline: none;

  &:focus {
    border-color: ${props => props.theme.color.primary.base};
    box-shadow: 0px 0px 0px 4px #f4deff;
  }
`

export default Select
