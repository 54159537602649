import styled from 'styled-components'

const Label = styled.label`
  font-size: 1.6rem;
  color: ${props => props.theme.color.text.lightest};
  margin: 0 0 6px;
  display: block;
`

export default Label
