import styled from 'styled-components'

const Input = styled.input`
  height: 52px;
  padding: 0 15px;
  border: 1px solid ${props => props.theme.color.grey.darker};
  border-radius: 6px;
  color: ${props => props.theme.color.text};
  width: 100%;
  font-size: 1.6rem;
  outline: none;

  &:focus {
    border-color: ${props => props.theme.color.primary.base};
    box-shadow: 0px 0px 0px 4px #f4deff;
  }
`

export default Input
