import React from 'react'
import styled from 'styled-components'

const Checkbox = ({label, name, value, id}) => (
  <Wrapper>
    <Input type="checkbox" name={name} value={value} id={id} />
    <Label htmlFor={id}>{label}</Label>
  </Wrapper>
)

const Wrapper = styled.div`
  border: 1px solid ${p => p.theme.color.grey.darker};
  padding: 0 10px;
  border-radius: 4px;
  margin: 4px;
  display: flex;
  align-items: center;
`

const Label = styled.label`
  margin: 0 0 0 10px;
  font-size: 1.6rem;
  cursor: pointer;
  line-height: 2.5;
`

const Input = styled.input`
  width: 16px;
  height: 16px;
`

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
`

export {Checkbox, Group}
