import styled from 'styled-components'

const Fieldset = styled.fieldset.attrs(props => ({
  columns: props.columns || 1,
}))`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  margin: 0 0 25px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(${props => props.columns}, 1fr);
  }
`

export default Fieldset
